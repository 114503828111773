
<style>
.rounded-image-with-stroke {
  border-radius: 10px; /* Adjust the value to change the roundness */
  box-shadow: 0 0 5px rgba(0,0,0,0.3); /* Adjust the values to change the size and color of the stroke */
  width: 150px;
}
</style>
<template>
  <div>

  <!-- Modal picture-->
  <b-modal
    id="modal-picture"
    v-model="pictureModal"
    hide-footer
    centered
  >
    <img :src="`${ApiURL}upload/${asset.asset_photo}`" style="width: 100%;" alt="Asset Photo" />
    <b-button close @click="pictureModal = false" slot="modal-footer">Close</b-button>
  </b-modal>
    <b-row class="match-height">
      <b-col
lg="12"
><b-form
          @submit.prevent="submitEditAsset"
>
<b-card no-body class="mb-0">
  <b-row class="match-height">
    <b-col lg="12">
      <b-card title="Assets Detail">
        <b-row>
          <b-col cols="6">
            <b-button
              type="button"
              variant="primary"
              class="mr-1"
              @click="previous()"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                size="16"
                class="align-middle"
              />
              Back
            </b-button>
            </b-col>
          <b-col cols="6" class="d-flex justify-content-end">
            <img v-if="asset.asset_photo != ''" :src="`${ApiURL}upload/${asset.asset_photo}`" alt="Asset Photo" class="rounded-image-with-stroke" @click="showModal"/>
          </b-col>
          <b-col cols="12">
            <div class="devider" />
        <b-form-group
          label="Item ID "
          label-for="itemId"
          label-cols-md="12"
        >
          <b-form-input
            :value="asset.item.item_code"
            placeholder="itemId"
            readonly
          />
        </b-form-group>
        <b-form-group
          label="Asset ID "
          label-for="assetId"
          label-cols-md="12"
        >
          <b-form-input
            v-model="asset.asset_number"
            placeholder="Asset ID"
            readonly
          />
        </b-form-group>
        <b-form-group
          label="Ref ID "
          label-for="refId"
          label-cols-md="12"
        >
          <b-form-input
            v-model="asset.asset_ref_id"
            placeholder="Ref ID"
          />
        </b-form-group>
        <b-form-group
          label="Local ID "
          label-for="localId"
          label-cols-md="12"
        >
          <b-form-input
            v-model="asset.asset_local_id"
            placeholder="Local ID"
          />
        </b-form-group>
        <b-form-group
          label="Asset Group "
          label-for="category"
          label-cols-md="12"
        >
                <v-select-new v-model="asset.asset_category" :options="categories" required/>
        </b-form-group>
        <b-form-group
          label="Description "
          label-for="description"
          label-cols-md="12"
        >
          <b-form-input
            v-model="asset.asset_description"
            placeholder="Asset Description"
          />
        </b-form-group>
        <b-form-group
          label="Note "
          label-for="note"
          label-cols-md="12"
        >
          <b-form-input
            v-model="asset.asset_note"
            placeholder="Asset Note"
          />
        </b-form-group>
        <b-form-group
          label="GPS"
          label-for="gps"
          label-cols-md="12"
        >
          <b-form-input
            v-model="asset.asset_gps"
            placeholder="Asset GPS"
          />
        </b-form-group>
        <b-form-group
          label="Date of Acquisition "
          label-cols-md="12"
        >
                <b-form-datepicker v-model="asset.asset_date_of_acquistion" placeholder="Asset Date of Acquisition" required/>
        </b-form-group>
        <b-form-group
                label="Acquisition Value"
                label-for="assetAcquisitionValue"
                label-cols-md="12"
              >
                <b-form-input
                  v-model="asset.asset_acquistion_value"
                  placeholder="Asset Acquisition Value"
                />
              </b-form-group>
              <b-form-group
                label="Depreciation Method "
                label-for="assetDepreciationMethod"
                label-cols-md="12"
              >
                <v-select-new v-model="asset.asset_depreciation_method" :options="methods" required/>
              </b-form-group>
              <b-form-group
                label="Residual Value "
                label-for="assetResidualValue"
                label-cols-md="12"
              >
                <b-form-input
                  v-model="asset.asset_residual_value"
                  placeholder="Asset Residual Value"
                  type="number"
                />
              </b-form-group>
              <b-form-group
                label="Market Value "
                label-for="assetMarketValue"
                label-cols-md="12"
              >
                <b-form-input
                  v-model="asset.asset_market_value"
                  placeholder="Asset Market Value"
                  type="number"
                />
              </b-form-group>
              <b-form-group
                label="Asset Condition "
                label-for="assetAssetCondition"
                label-cols-md="12"
              >
                <v-select-new v-model="asset.asset_condition" :options="conditions" required/>
              </b-form-group>
              <b-form-group
                label="Asset Address "
                label-for="assetAddress"
                label-cols-md="12"
              >
                <b-form-input
                  v-model="asset.asset_address"
                  placeholder="Asset Address"
                />
              </b-form-group>
              <b-form-group
                label="Asset Responsible "
                label-for="assetResponsible"
                label-cols-md="12"
              >
                <b-form-input
                  v-model="asset.asset_responsible"
                  placeholder="Asset Responsible"
                />
              </b-form-group>
              <b-form-group
                label="Serial Number "
                label-for="assetSerialNumber"
                label-cols-md="12"
              >
                <b-form-input
                  v-model="asset.asset_serial_no"
                  placeholder="Asset Serial Number"
                />
              </b-form-group>
              <b-form-group
                label="Warranty "
                label-for="assetWarranty"
                label-cols-md="12"
              >
                <b-form-input
                  v-model="asset.asset_warranty"
                  placeholder="Asset Warranty"
                />
              <b-form-group label="Photo " label-for="assetPhoto" label-cols-md="12">
                <b-form-file
                  ref="uploadFilePhoto"
                  placeholder="Choose one file or drop it here."
                  drop-placeholder="Drop a file here..."
                  type="file"
                  @change="handleFilesUploadPhoto"
                />
              </b-form-group>
              </b-form-group>
          </b-col>
        </b-row>

        <b-button type="submit" variant="primary">
            Submit
          </b-button>
      </b-card>
    </b-col>
  </b-row>
  </b-card>
      </b-form>
      </b-col>
    </b-row>
  </div>
  </template>
  <script>
  import {
    BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BCard, BListGroup, BListGroupItem, BFormDatepicker, BFormFile,
    } from 'bootstrap-vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import axios from 'axios'
  import moment from 'moment'
  import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'

  const submitButtonDisabled = false
  const VersionCheck = ''
  const items = []
  const chosenItem = ref(3)
  const types = [
      { label: "Head Office", value: "true" },
      { label: "Cabang", value: "false" },
    ]
  const chosens = []
  const photoFile = ""
  const asset = null
  const ApiURL = process.env.VUE_APP_API_URL
  const pictureModal = false
  const categories = ["Machinery (MC)", "Furniture & Fixture", "Office Equipment", "Lab Equipment", "Vehicle (VH)", "Building", "Software", "Low Value Asset", "Land"]
  const methods = ["Metode Garis Lurus", "Metode Saldo Menurun", "Metode Unit Produksi", "Metode Jam Kerja", "Metode Penyusutan Akselerasi"]
  const conditions = ["Baik", "Sedang", "Buruk"]
  export default {
    components: {
  BFormDatepicker,
      BListGroup,
      BListGroupItem,
      BCard,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BForm,
      BButton,
  BFormFile,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        photoFile,
        categories,
        methods,
        conditions,
        pictureModal,
        ApiURL,
        asset,
        types,
      VersionCheck,
      items,
      chosenItem,
      chosens,
      submitButtonDisabled,
      }
    },
    mounted() {
        this.getItems()
        this.getDetail()
    },
    methods: {
    getDetail() {
      const itemDetail = JSON.parse(localStorage.getItem('assetDetail'))
      this.asset = itemDetail
    },
    showModal() {
      this.pictureModal = true
    },
    getItems() {
      const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
      axios
        .get(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_ITEMS}`, { headers })
        .then(response => {
          if (response.data.success === true) {
                const itemsJoin = response.data.data.map(elem => (
                  { label: elem.item_name, value: elem.item_id }
                ))
                this.items = itemsJoin
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Get Data Success',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Get Data Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
        .catch(e => {
          console.log(e)
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Get Settings Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    apiUrl() {
    return process.env.VUE_APP_API_URL || 'fallback_url_if_needed'
  },
    submitEditAsset() {
      const userToken = this.$cookies.get('userToken')
  const headers = {
      'Content-Type': 'multipart/form-data',
    Authorization: `Bearer ${userToken}`,
  }
      this.submitButtonDisabled = true
      const formData = new FormData()
      formData.append('asset_id', this.asset.asset_id)
      formData.append('asset_ref_id', this.asset.asset_ref_id)
      formData.append('asset_local_id', this.asset.asset_local_id)
      formData.append('asset_category', this.asset.asset_category)
      formData.append('asset_description', this.asset.asset_description)
      formData.append('asset_gps', this.asset.asset_gps)
      formData.append('asset_date_of_acquistion', this.asset.asset_date_of_acquistion)
      formData.append('asset_acquistion_value', this.asset.asset_acquistion_value)
      formData.append('asset_depreciation_method', this.asset.asset_depreciation_method)
      formData.append('asset_residual_value', this.asset.asset_residual_value)
      formData.append('asset_market_value', this.asset.asset_market_value)
      formData.append('asset_condition', this.asset.asset_condition)
      formData.append('asset_address', this.asset.asset_address)
      formData.append('asset_responsible', this.asset.asset_responsible)
      formData.append('asset_serial_number', this.asset.asset_serial_number)
      formData.append('asset_note', this.asset.asset_note)
      formData.append('asset_warranty', this.asset.asset_warranty)
      formData.append('time', moment())
if (typeof this.photoFile[0] !== "undefined") {
formData.append("photo", this.photoFile[0], this.photoFile[0].name)
}
      axios
        .post(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_EDIT_ASSET}`, formData, { headers })
        .then(response => {
          if (response.data.success === true) {
            this.chosens = []
            this.AssetQtyRequested = 0
            this.AssetReason = ""
            this.AssetCc = ""
            this.AssetTo = ""
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Edit Asset Success',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$router.replace({ name: 'apps-procurement-assets-list' })
          } else {
            this.submitButtonDisabled = false
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Edit Asset Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
                title: 'Edit Asset Failed',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
handleFilesUploadPhoto(e) {
      this.photoFile = e.target.files || e.dataTransfer.files
    },
      getOptionLabel(option) {
        return (option && option.label) || ''
      },
      putItem(val) {
        if (this.chosens.length > 0) {
          let same = false
          this.chosens.map(elem => {
                if (elem.value === val.value) {
                  same = true
                }
          })
          if (same === false) {
              let item = null
              this.items.map(elem => {
                if (elem.value === val.value) {
                  item = {
                    label: elem.label, value: elem.value, qty: 1, item: elem.item,
                   }
                }
              })
            this.chosens.push(item)
          }
        } else {
              let item = null
              this.items.map(elem => {
                if (elem.value === val.value) {
                  item = {
                    label: elem.label, value: elem.value, qty: 1, item: elem.item,
                  }
                }
            })
            this.chosens.push(item)
        }
      },
      applyItem(val) {
        console.log(val.data)
      },
      getOptionKey(option) {
        return (option && option.value) || ''
      },
      inputSearch(search) {
        console.log(search)
      },
      removeRow(val) {
        this.chosens = this.chosens.filter(obj => (obj.value !== val))
      },
      dateFormat(value, format = 'MM/DD/YYYY') {
          return moment(String(value)).format(format)
      },
        previous() {
      this.$router.push({ name: "apps-procurement-assets-list" })
        },
    },
  }
  </script>
